<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <v-card-title class="display-2">
        <v-row>
          <v-col class="pa-0 pl-4 grow">Manage a group</v-col>
          <v-col v-if="group" class="pa-0 pr-4 shrink"><v-btn color="accent" :to="{name:'profileGroupsView', params: {id: group.id}}"><v-icon small class="fal fa-eye mr-2" /> View group</v-btn></v-col>
        </v-row>
      </v-card-title>

      <div v-if="group">
        <v-card-title class="subtitle py-0">Group details</v-card-title>
        <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>      
            <v-text-field 
              v-model="group.name" 
              :rules="nameRules" 
              label="Group name (required)" 
              hint="The name of your group."
              prepend-icon="fa-pencil-alt"
              required>
          </v-text-field>
          <v-textarea 
              v-model="group.description" 
              label="Description (optional)" 
              prepend-icon="fa-comment"
              rows="2"
              auto-grow>
          </v-textarea>
          <v-row>
            <v-col cols="12" md="6">
              <v-switch v-model="group.public" persistent-hint hint="When enabled this group is available for everyone to see, otherwise they may only join when they have the url." prepend-icon="fa-eye" class="mt-0" label="Is public (available for others to see)"></v-switch>
            </v-col>
            <v-col cols="12" md="6">
            <v-select
              :items="siteData.group_types"
              v-model="group.type" 
              item-value="type"
              item-text="text"
              label="Type of group"
              persistent-hint
              hint="Optionally you can mark this as a special group which can be linked to your challenges."
            ></v-select>
            </v-col>
          </v-row>
        </v-card-text>      
        <v-card-title class="subtitle py-0">Design</v-card-title>
        <v-card-text>      
          <p>Customize the design of your group page. Click 'View' at the top of the page (after this group is saved) to view the actual design.</p>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-btn-toggle v-model="calculatedGroupIcon" mandatory class="multiline-btns">
                <v-btn value="_" small>
                  None
                </v-btn>
                <v-btn v-for="(item, idx) in $helpers.Icons" :key="idx" :value="item" small>
                  <v-icon>fa-{{item}}</v-icon>
                </v-btn>
              </v-btn-toggle>
              <v-text-field 
                v-model="group.color" 
                label="Group Color" 
              >
                <template v-slot:append>
                  <v-menu v-model="showColorPickerMenu" right :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="colorForBinding" show-swatches mode="hexa" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>                        
              </v-text-field>
              <v-progress-circular v-if="generatingPreviewImage" size="40" indeterminate />
              <img v-if="group.icon" :src="group.logo" width="200" @load="generatingPreviewImage = false" />
            </v-col>

            <v-col v-if="!group.icon" cols="12" md="6" class="py-0">
              <p>Logo (optional, square ~ 500 x 500 px)</p>
              <EventImageUploadForm :group-id="group.id" :img="group.logo" type="logo" />
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <p>Header image at the top of the group page (optional, landscape format ~ 1150 x 300 px)</p>
              <EventImageUploadForm :group-id="group.id" :img="group.img" type="image" />
            </v-col>
          </v-row>
        </v-card-text>   

        <v-card-text>   
          <v-btn class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
          <v-snackbar v-model="savedSnackbar" top color="success" :timeout="6000">
            Hooray, your group is saved!
            <v-btn v-if="group" text color="white" target="_blank" :to="{name:'profileGroupsView', params: {id: group.id}}">View group</v-btn>
          </v-snackbar>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        </v-card-text>   
        </v-form>
      </div>      
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'

export default {
  name: "ProfileGroupsEdit",
  components: {
    EventImageUploadForm,
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: 'Edit group', disabled: true },
      ],
      valid: true,
      savedSnackbar: false,
      error: null,
      group: null,
      generatingPreviewImage: false,
      siteData: siteData,
      showColorPickerMenu: false,
      nameRules: [
        v => !!v || "Please enter the group name",
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.error = null;
        try {
          var response = await profileService.editGroup(this.group.id, this.group);
          await this.getGroup(response.data.id);
          this.savedSnackbar = true;
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    async getGroup(id) {
      var response = await profileService.getGroup(id);
      this.group = response.data;
    },
    async previewGroupLogo() {
      if (!this.group.icon) return;

      this.generatingPreviewImage = true;
      var response = (await profileService.previewGroupLogo(this.group.id, this.group)).data;
      if (response.status === 'OK') {
        this.group.logo = response.msg;
      }
      //this.generatingPreviewImage = false;
    },

    async getProfile() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },

  },
  computed: {
    colorForBinding: {
      get() {
        console.log('GET', this.group.color);
        if (!this.group.color || this.group.color.length == 0) {
          return "#FFFFFF";
        }
        return this.group.color;
      },
      async set(value) {
        console.log('SET', value);
        if (value && value.length > 7) {
          value = value.substring(0, 7).toUpperCase();
          if (value === '#FFFFFF') {
            value = null;
          }
        }
        this.group.color = value;
        await this.previewGroupLogo();
        this.$forceUpdate();
      }
    },
    swatchStyle() {
      const { event, showColorPickerMenu } = this
      return {
        backgroundColor: this.group.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: 'solid 1px #ccc',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }, 
    calculatedGroupIcon:{
      get () {
        return !this.group || !this.group.icon ? '_' : this.group.icon
      },
      async set (value) {
        this.group.icon = value === '_' ? null : value;
        await this.previewGroupLogo();
        this.$forceUpdate();
      }
    },       
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
  .multiline-btns { flex-wrap: wrap; }
</style>

